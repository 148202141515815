.navbar{
    width: 100%;
    height: 45px;
    background-color: #333;
    display: flex;
    align-items: center;
    i{
        cursor: pointer;
        color: white;
        font-size: 30px;
        margin-left: 20px;
    }
}
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .image-card {
    width: 300px;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .image-card img {
    width: 100%;
    height: 100%;
  }
  .del-btn{
    background-color: red;
    color: white;
    height: 40px;
    border: none;
  }

.contact-form-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .contact-form-list li {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 400px;
    height: auto;
    overflow: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .contact-form-list h4 {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .contact-form-list p {
    font-size: 16px;
    margin: 5px 0;
  }
  
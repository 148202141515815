    textarea {
        width: 300px;
        height: 100px;
        border-radius: 6px;
        padding-left: 10px;
        outline: none;
        padding-top: 10px;
        border: 2px solid #000;
        font-size: 18px;
        margin-bottom: 10px;
    }
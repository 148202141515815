// Team.scss

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 50px;
  }
  
  .team-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
    }
  
    img {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #ccc;
    }
  
    .team-content {
      padding: 15px;
  
      h3 {
        margin: 0;
        font-size: 18px;
        color: #333;
      }
    }
  
    .team-actions {
      display: flex;
      justify-content: space-between;
      padding: 10px;
  
      button {
        padding: 8px 16px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &.edit-btn {
          background-color: #4caf50;
          color: #fff;
  
          &:hover {
            background-color: #45a049;
          }
        }
  
        &.delete-btn {
          background-color: #f44336;
          color: #fff;
  
          &:hover {
            background-color: #d32f2f;
          }
        }
      }
    }
  }
  
  .main {
    padding: 20px;
  
    h2 {
      margin-bottom: 20px;
    }
  
    .main-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      label {
        display: flex;
        flex-direction: column;
        gap: 8px;
  
        input,
        select {
          padding: 10px;
          font-size: 14px;
        }
  
        select {
          width: 80vw;
        }
      }
  
      button {
        padding: 10px;
        font-size: 16px;
        background-color: #2196f3;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: #0b7dda;
        }
      }
    }
  }
  
.main{
    h2{
        text-align: center;
        margin-top: 10px;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .main-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        button{
            width: 80vw;
            height: 40px;
            border-radius: 6px;
            border: none;
            background-color: rgb(26, 145, 26);
            color: white;
            cursor: pointer;
        }
        label{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 22px;
            input{
                width: 80vw;
                height: 50px;
                border-radius: 6px;
                padding-left: 10px;
                outline: none;
            }
            textarea{
                width: 80vw;
                height: 200px;
                border-radius: 6px;
                padding-left: 10px;
                outline: none;
                padding-top: 10px;
                border: 2px solid #000;
            }
        }
    }
}
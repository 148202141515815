.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    input{
        width: 300px;
        height: 50px;
        margin: 10px;
        padding-left: 10px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 0 5px black;
        outline: none;
    }
    button{
        width: 300px;
        height: 50px;
        border-radius: 6px;
        border: none;
        background-color: rgb(10, 146, 10);
        color: white;
        box-shadow: 0 0 5px green;
    }
}
// LoginEdit.scss

.Forgot {
    padding: 20px;
    .title {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 300px;
      margin: 0 auto;
      input {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      button {
        padding: 10px;
        font-size: 18px;
        background-color: #2196f3;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: #0b7dda;
        }
      }
    }
  }
  
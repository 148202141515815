.error{
    text-align: center;
    font-size: 50px;
    margin-top: 100px;
}
.errorlink{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 30px;
}